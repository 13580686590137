<template>
  <div class="subscription-tab-wrapper">
    <div v-if="loading" class="d-flex justify-center mt-8">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <div v-else>
      <div v-if="isSubscriptionNotDefined">
        <h5>_No subscription required</h5>
        <p>_To use this device you do not need to have subscription</p>
      </div>

      <div v-else class="subscription-info">
        <h5>
          {{
            isMoreThan30DaysLater(subscriptionData?.expirationDate)
              ? 'Subscription will end soon'
              : 'Active subscription'
          }}
        </h5>
        <p>
          Subscription expires: <b>{{ formattedExpirationDate }}</b>
        </p>
        <p>
          Subscription from: <b>{{ formattedStartDate }}</b>
        </p>
        <button
          v-if="isMoreThan30DaysLater(subscriptionData?.expirationDate)"
          class="v-btn primary v-size--default renew-button"
          @click="openDialog"
        >
          Renew
        </button>
      </div>
    </div>
    <SubscriptionRenewDialog :value="dialogOpen" :onClose="closeDialog" :device="device" />
  </div>
</template>
<script>
import SubscriptionRenewDialog from '../../Subscription/SubscriptionRenewDialog.vue'
import { subscriptionStatus } from '@/graphql/query/subscriptionStatus'

export default {
  name: 'SubscriptionOverviewTab',
  components: {
    SubscriptionRenewDialog,
  },
  props: {
    tabConfig: Object,
    device: {
      type: Object,
      required: true,
    },
    deviceDetailWrapper: HTMLDivElement,
    active: Boolean,
  },
  data() {
    return {
      dialogOpen: false,
      loading: false,
    }
  },
  apollo: {
    subscriptionData: {
      query: subscriptionStatus,
      variables() {
        return {
          deviceId: this.device.id,
        }
      },
      update(data) {
        return {
          subscriptionStatus: data.subscriptionStatus.subscriptionStatus.toLowerCase(),
          expirationDate: data.subscriptionStatus.expirationDate,
          startDate: data.subscriptionStatus.startDate,
        }
      },
      error(error) {
        console.error('Error fetching subscription status:', error)
        this.$toast.error(this.$t('global.errorFetchingData'))
      },
      loadingKey: 'loading',
    },
  },
  computed: {
    isSubscriptionValid() {
      return this.subscriptionData?.subscriptionStatus === 'valid'
    },
    isSubscriptionExpired() {
      return this.subscriptionData?.subscriptionStatus === 'expired'
    },
    isSubscriptionNotDefined() {
      return this.subscriptionData?.subscriptionStatus === 'not_defined'
    },
    formattedStartDate() {
      return this.subscriptionData?.startDate
        ? this.formatDate(this.subscriptionData.startDate)
        : '-'
    },
    formattedExpirationDate() {
      return this.subscriptionData?.expirationDate
        ? this.formatDate(this.subscriptionData.expirationDate)
        : '-'
    },
  },
  methods: {
    closeDialog() {
      this.dialogOpen = false
    },
    openDialog() {
      this.dialogOpen = true
    },
    isMoreThan30DaysLater(timestamp) {
      const now = new Date()
      const futureDate = new Date()
      futureDate.setDate(now.getDate() + 30)
      return timestamp > futureDate.getTime()
    },
    formatDate(timestamp) {
      if (!timestamp) return '-'
      try {
        const date = new Date(parseInt(timestamp))
        if (isNaN(date.getTime())) return '-'
        return `${String(date.getDate()).padStart(2, '0')}-${String(date.getMonth() + 1).padStart(
          2,
          '0',
        )}-${date.getFullYear()}`
      } catch (error) {
        console.error('Error formatting date:', error)
        return '-'
      }
    },
  },

  //   apollo: {
  //     chargingHistoryResult: {
  //       query: chargingHistory,
  //       variables() {
  //         return {
  //           deviceId: this.device.id,
  //           first: this.pageSize,
  //           after: this.endCursorToLoad,
  //         }
  //       },
  //       update(data) {
  //         const newData = data.device.chargingHistory.edges.map(
  //           (chargingHistoryEntryEdge) => chargingHistoryEntryEdge.node,
  //         )
  //         this.endCursor = data.device.chargingHistory.pageInfo.endCursor
  //         this.hasNextPage = data.device.chargingHistory.pageInfo.hasNextPage
  //         this.chargingHistory.push(...newData)
  //         return newData
  //       },
  //     },
  //   },
}
</script>
<style lang="less" scoped>
@import '~@/assets/less/variables.less';

.subscription-tab-wrapper {
  h1 {
    font-size: 24px;
  }

  h2 {
    font-weight: 400;
    font-size: 14px;
    color: #8089ac;
  }
  h5 {
    font-size: 1rem;
    margin-bottom: 8px;
  }
  p {
    font-size: 0.875rem;
    margin-bottom: 0;
  }

  .renew-button {
    width: fit-content;
    margin-top: 16px;
  }

  .subscription-info {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  .action-btn-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;

    .vertical-line {
      width: 1px;
      align-self: stretch;
      background: black;
      opacity: 0.07;
    }
  }
}
</style>
