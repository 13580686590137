<template>
  <v-dialog
    :value="isOpen"
    @input="$emit('modalToggle', $event)"
    :content-class="mobileView ? 'daily-time-schedules-modal-mobile' : 'daily-time-schedules-modal'"
    :fullscreen="mobileView"
    width="unset"
  >
    <v-btn
      v-if="mobileView && mobileViewDetail"
      @click="mobileViewDetail = false"
      class="back-btn"
      icon
      ><v-icon>mdi-arrow-left</v-icon></v-btn
    >
    <v-btn
      @click="$emit('modalToggle', false)"
      :color="mobileView ? 'default' : 'white'"
      class="close-btn"
      icon
      ><v-icon>mdi-close</v-icon></v-btn
    >
    <div class="modal-content-wrapper">
      <div v-show="!mobileView || !mobileViewDetail" class="sidebar">
        <h1>{{ $t('global.dailyTimeSchedules') }}</h1>
        <div class="schedule-list">
          <button
            v-for="(dailyTimeSchedule, index) of dailyTimeSchedules"
            :key="dailyTimeSchedule.id"
            @click=";(selectedDailyScheduleIndex = index), (mobileViewDetail = true)"
            class="schedule-selector-btn"
            :class="{ active: !mobileView && index === selectedDailyScheduleIndex }"
          >
            <div class="schedule-selector-btn-texts">
              <h2>{{ dailyTimeSchedule.name }}</h2>
              <!-- <p>
                {{
                  dailyTimeSchedule.isGlobal
                    ? $t('global.predefinedSchedule')
                    : $t('global.mySchedule')
                }}
              </p> -->
            </div>
            <div v-if="editable" class="action-buttons" :class="{ 'always-visible': mobileView }">
              <v-btn
                :title="$t('global.duplicateSchedule')"
                icon
                height="24"
                width="24"
                @click.stop="duplicateSchedule(index)"
                ><v-icon small>mdi-content-duplicate</v-icon></v-btn
              >
              <v-btn
                v-if="(!dailyTimeSchedule.isGlobal || templateMode) && !dailyTimeSchedule.isUsed"
                :title="$t('global.delete')"
                icon
                height="24"
                width="24"
                @click.stop="openDeleteConfirmationModal(index)"
                ><v-icon small>mdi-delete</v-icon></v-btn
              >
              <v-btn
                v-if="!dailyTimeSchedule.isGlobal || templateMode"
                :title="$t('global.edit')"
                icon
                height="24"
                width="24"
                @click.stop="editSchedule(index)"
                ><v-icon small>mdi-pencil</v-icon></v-btn
              >
            </div>
          </button>
        </div>
        <div v-if="editable" class="create-btn-wrapper">
          <v-btn outlined depressed @click="startCreatingSchedule()">{{
            $t('global.newDailySchedule')
          }}</v-btn>
        </div>
      </div>
      <div v-show="!mobileView || mobileViewDetail" class="daily-schedule-detail-wrapper">
        <TimeScheduleCalendarInput
          ref="calendar"
          :timeSchedule="normalizedSelectedTimeSchedule"
          :timeScheduleConfig="timeScheduleConfig"
          :editable="false"
        />

        <footer
          class="pa-6"
          :class="[
            templateMode || !dailyTimeSchedules[selectedDailyScheduleIndex].isGlobal
              ? 'justify-space-between'
              : 'justify-end',
          ]"
          v-if="editable"
        >
          <v-tooltip
            v-if="templateMode || !dailyTimeSchedules[selectedDailyScheduleIndex].isGlobal"
            top
            :disabled="!disabledDeleteMessage"
          >
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-btn
                  v-if="timeScheduleConfig.schedules.length > 1"
                  :disabled="Boolean(disabledDeleteMessage)"
                  color="error"
                  text
                  @click="openDeleteConfirmationModal(selectedDailyScheduleIndex)"
                  ><v-icon left>mdi-delete</v-icon>{{ $t('global.delete') }}</v-btn
                >
                <v-btn
                  v-if="timeScheduleConfig.schedules.length <= 1"
                  :disabled="Boolean(disabledDeleteMessage)"
                  color="error"
                  text
                  small
                  @click="openDeleteConfirmationModal(selectedDailyScheduleIndex)"
                  ><v-icon center>mdi-delete</v-icon></v-btn
                >
              </div>
            </template>
            <span>{{ disabledDeleteMessage }}</span>
          </v-tooltip>
          <div class="actions">
            <v-tooltip v-if="editable" top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  @click="duplicateSchedule(selectedDailyScheduleIndex)"
                  depressed
                  >{{ $t('global.duplicateSchedule') }}</v-btn
                >
              </template>
              <span>{{ $t('global.duplicateScheduleTooltip') }}</span>
            </v-tooltip>
            <v-btn
              v-if="
                editable &&
                (!dailyTimeSchedules[selectedDailyScheduleIndex].isGlobal || templateMode)
              "
              @click="editSchedule(selectedDailyScheduleIndex)"
              depressed
              >{{ $t('global.edit') }}</v-btn
            >
          </div>
        </footer>
      </div>
    </div>

    <DailyTimeScheduleEditModal
      v-if="baseScheduleForEditModal"
      v-model="isEditDialogOpen"
      :timeSchedule="baseScheduleForEditModal"
      :timeScheduleConfig="timeScheduleConfig"
      :saveMethod="editModalSaveMethod"
      :deleteMethod="deleteMethod"
      :disabledDeleteMessage="disabledDeleteMessageForEditModal"
      :namesTaken="namesTakenForEditModal"
      :updateMode="editModalIsInUpdateMode"
      :usedInWeeklySchedulesNames="usedInWeeklyForEditModal"
    />

    <ConfirmationDialog
      v-if="deleteConfirmationModalOpen"
      v-model="deleteConfirmationModalOpen"
      :heading="$t('global.dailyTimScheduleDeleteConfirmationHeading')"
      :text="
        $t('global.dailyTimScheduleDeleteConfirmationText', {
          scheduleName: dailyTimeSchedules[indexToDelete].name,
        })
      "
      :confirmText="$t('global.delete')"
      confirmBtnColor="error"
      :action="deleteDailyTimeSchedule"
    />
  </v-dialog>
</template>
<script>
import TimeScheduleCalendarInput from '@/components/TimeSchedules/TimeScheduleCalendarInput'
import DailyTimeScheduleEditModal from '@/components/TimeSchedules/DailyTimeScheduleEditModal'
import ConfirmationDialog from '@/components/ConfirmationDialog'
import { normalizeAndSortTimeSchedule } from '@/utils/timeSchedule'
import produce from 'immer'
import startOfDay from 'date-fns/startOfDay'
import differenceInSeconds from 'date-fns/differenceInSeconds'

export default {
  name: 'DailyTimeSchedulesModal',
  components: { TimeScheduleCalendarInput, ConfirmationDialog, DailyTimeScheduleEditModal },
  model: {
    prop: 'isOpen',
    event: 'modalToggle',
  },
  props: {
    isOpen: Boolean,
    dailyTimeSchedules: Array,
    weeklyTimeSchedules: Array,
    timeScheduleConfig: Object,
    editable: Boolean,
    createMethod: Function,
    updateMethod: Function,
    deleteMethod: Function,
    preselectScheduleId: String,
    templateMode: Boolean,
  },
  data() {
    return {
      selectedDailyScheduleIndex: 0,
      deleteConfirmationModalOpen: false,
      isEditDialogOpen: false,
      editModalSaveMethod: null,
      baseScheduleForEditModal: null,
      namesTakenForEditModal: null,
      disabledDeleteMessageForEditModal: null,
      editModalIsInUpdateMode: false,
      indexToDelete: null,
      mobileViewDetail: false,
      doNotResetMobileView: false,
      usedInWeeklyForEditModal: null,
    }
  },
  methods: {
    async deleteDailyTimeSchedule() {
      try {
        await this.deleteMethod(this.dailyTimeSchedules[this.indexToDelete].id)
        if (this.indexToDelete === this.selectedDailyScheduleIndex) {
          this.selectedDailyScheduleIndex = 0
        } else if (this.indexToDelete < this.selectedDailyScheduleIndex) {
          this.selectedDailyScheduleIndex--
        }
        this.deleteConfirmationModalOpen = false
      } catch (error) {
        throw error
      }
    },
    async createDailyTimeSchedule(newSchedule, newName) {
      try {
        await this.createMethod(newSchedule, newName)
        this.selectedDailyScheduleIndex = 0
      } catch (error) {
        throw error
      }
    },
    editSchedule(index) {
      this.editModalSaveMethod = this.updateMethod
      this.baseScheduleForEditModal = this.dailyTimeSchedules[index]
      this.namesTakenForEditModal = this.removeName(
        this.dailyTimeSchedules[index].name,
        this.localDailyTimeSchedulesNames,
      )
      this.editModalIsInUpdateMode = true
      this.disabledDeleteMessageForEditModal = this.disabledDeleteMessage
      this.usedInWeeklyForEditModal = this.weeklyTimeSchedules
        .filter((weeklySchedule) =>
          weeklySchedule.weeklySchedule.find(
            (entry) => entry.dailySchedule.id === this.dailyTimeSchedules[index].id,
          ),
        )
        .map((weeklySchedule) => weeklySchedule.name)
      this.isEditDialogOpen = true
    },
    duplicateSchedule(index) {
      this.editModalSaveMethod = this.createDailyTimeSchedule
      this.baseScheduleForEditModal = produce(this.dailyTimeSchedules[index], (draft) => {
        draft.name = `${draft.name} ${this.$t('global.duplicateSchedulePostfix')}`
      })
      this.namesTakenForEditModal = this.localDailyTimeSchedulesNames
      this.editModalIsInUpdateMode = false
      this.disabledDeleteMessageForEditModal = null
      this.isEditDialogOpen = true
    },
    openDeleteConfirmationModal(index) {
      this.indexToDelete = index
      this.deleteConfirmationModalOpen = true
    },
    startCreatingSchedule() {
      this.editModalSaveMethod = this.createDailyTimeSchedule
      this.baseScheduleForEditModal = {
        name: '',
        schedule: Object.fromEntries(
          this.timeScheduleConfig.schedules.map((scheduleConfig) => [scheduleConfig.name, []]),
        ),
      }
      this.namesTakenForEditModal = this.localDailyTimeSchedulesNames
      this.editModalIsInUpdateMode = false
      this.disabledDeleteMessageForEditModal = null
      this.isEditDialogOpen = true
    },
    removeName(name, namesTaken) {
      const index = namesTaken.indexOf(name)
      if (index === -1) {
        return namesTaken
      }
      const namesCopy = [...namesTaken]
      namesCopy.splice(index, 1)
      return namesCopy
    },
  },
  computed: {
    mobileView() {
      return this.$vuetify.breakpoint.smAndDown
    },
    normalizedSelectedTimeSchedule() {
      return normalizeAndSortTimeSchedule(
        this.dailyTimeSchedules[this.selectedDailyScheduleIndex].schedule,
      )
    },
    localDailyTimeSchedulesNames() {
      if (!this.dailyTimeSchedules) {
        return []
      }
      return this.dailyTimeSchedules
        .filter((schedule) => !schedule.isGlobal || this.templateMode)
        .map((schedule) => schedule.name)
    },
    disabledDeleteMessage() {
      if (this.dailyTimeSchedules[this.selectedDailyScheduleIndex].isUsed) {
        return this.$t('global.disabledDeleteTooltipForDailyScheduleUsed')
      }
      return null
    },
    dailyScheduleMap() {
      const map = new Map()
      this.dailyTimeSchedules.forEach((schedule) => {
        map.set(schedule.id, schedule)
      })
      return map
    },
  },
  watch: {
    isEditDialogOpen() {
      if (!this.isEditDialogOpen) {
        this.baseScheduleForEditModal = null
        this.$emit('modalToggle', true)
      } else {
        this.doNotResetMobileView = true
        this.$emit('modalToggle', false)
      }
    },
    isOpen() {
      if (this.isOpen) {
        if (!this.doNotResetMobileView) {
          this.mobileViewDetail = false
        } else {
          this.doNotResetMobileView = false
        }

        this.$nextTick(() => {
          const now = new Date()
          this.$refs.calendar.scrollTo(differenceInSeconds(now, startOfDay(now)))
        })

        const preselectedIndex = this.dailyTimeSchedules.findIndex(
          (schedule) => schedule.id === this.preselectScheduleId,
        )
        this.selectedDailyScheduleIndex =
          preselectedIndex === -1 ? this.selectedDailyScheduleIndex || 0 : preselectedIndex
      }
    },
    mobileViewDetail() {
      if (this.mobileViewDetail) {
        this.$nextTick(() => {
          const now = new Date()
          this.$refs.calendar.scrollTo(differenceInSeconds(now, startOfDay(now)))
        })
      }
    },
  },
}
</script>
<style lang="less" scoped>
@import '~@/assets/less/variables';
@import '~@/components/TimeSchedules/timeSchedule.less';

::v-deep .v-btn.v-size--small {
  height: 100% !important;
}
/deep/.daily-time-schedules-modal {
  height: 825px;
  overflow: visible;
  border-radius: 16px;
  position: relative;
}

/deep/.daily-time-schedules-modal-mobile {
  position: relative;

  .sidebar {
    flex-grow: 1;
  }

  .close-btn {
    top: 3px;
    right: 3px;
    z-index: 10;
  }

  .back-btn {
    position: absolute;
    top: 3px;
    left: 3px;
  }

  .sidebar .schedule-list .action-buttons.always-visible {
    display: flex;
  }
}

.modal-content-wrapper {
  display: flex;
  height: 100%;
  overflow: hidden;
  border-radius: 16px;
}

.sidebar {
  background: #eee;
  height: 100%;
  padding: 32px;
  display: flex;
  flex-direction: column;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  width: 350px;

  h1 {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 24px;
  }

  .schedule-list {
    display: flex;
    flex-direction: column;
    gap: 5px;
    overflow: auto;
    flex-grow: 1;

    .schedule-selector-btn {
      padding: 17px 15px 17px 21px;
      border-radius: 8px;
      text-align: left;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .schedule-selector-btn-texts {
        overflow: hidden;
      }

      h2 {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      p {
        font-weight: 400;
        font-size: 13px;
        line-height: 15px;
        opacity: 0.4;
        margin-bottom: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &.without-action-btns {
        padding: 17px 21px;
      }

      &.active {
        background: white;
      }

      &:hover:not(.active) {
        background: #e4e4e4;
      }

      .action-buttons {
        display: none;
      }

      &:hover {
        .action-buttons {
          display: flex;
        }
      }
    }
  }

  .create-btn-wrapper {
    margin-top: 32px;
    text-align: center;
  }
}

.daily-schedule-detail-wrapper {
  background: white;
  flex: 1;
  border-left: 1px solid #e0e0e0;
  display: flex;
  flex-direction: column;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}

footer {
  display: flex;
  border-top: 1px solid #e8e8e8;

  .actions {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
}

.close-btn {
  position: absolute;
  top: -39px;
  right: -8px;
}
</style>
