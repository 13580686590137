import gql from 'graphql-tag'

export const subscriptionStatus = gql`
  query SubscriptionStatus($deviceId: ID!) {
    subscriptionStatus(deviceId: $deviceId) {
      subscriptionStatus
      expirationDate
      startDate
    }
  }
`
