<template>
  <v-dialog :value="value" @input="$emit('input', $event)" width="unset">
    <v-card>
      <v-card-title>Vaše údaje</v-card-title>
      <v-card-subtitle>
        Rádi vás vidíme! Řekněte nám, komu máme tento nákup odeslat.
      </v-card-subtitle>

      <v-form ref="form" v-model="valid" style="padding: 24px">
        <v-text-field
          v-model="form.firstName"
          label="Jméno"
          :rules="[rules.required]"
        ></v-text-field>
        <v-text-field
          v-model="form.lastName"
          label="Příjmení"
          :rules="[rules.required]"
        ></v-text-field>
        <v-text-field
          v-model="form.company"
          label="Společnost"
          :rules="[rules.required]"
        ></v-text-field>

        <v-row>
          <v-col cols="5">
            <v-select
              v-model="form.phonePrefix"
              :items="phonePrefixes"
              label="Předvolba"
              :rules="[rules.required]"
            ></v-select>
          </v-col>
          <v-col cols="7">
            <v-text-field
              v-model="form.phoneNumber"
              label="Číslo mobilního telefonu"
              :rules="[rules.required, rules.phone]"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-select
          v-model="form.country"
          :items="countries"
          label="Země"
          :rules="[rules.required]"
        ></v-select>

        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="form.street"
              label="Ulice"
              :rules="[rules.required]"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="form.streetNumber"
              label="Číslo popisné"
              :rules="[rules.required, rules.numeric]"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="form.apartmentNumber"
              label="Číslo bytu"
              :rules="[rules.required, rules.numeric]"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="form.zipCode"
              label="PSČ"
              :rules="[rules.required, rules.numeric]"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="form.city"
              label="Město"
              :rules="[rules.required]"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="onClose()" depressed>
            {{ $t('global.cancel') }}
          </v-btn>
          <v-btn color="orange" dark @click="submitForm" depressed>
            {{ $t('global.confirm') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import { subscriptionRenew } from '@/graphql/mutations/subscriptionRenew'

export default {
  name: 'SubscriptionRenewDialog',

  data() {
    return {
      valid: false,
      form: {
        firstName: '',
        lastName: '',
        company: '',
        phonePrefix: 'CZ (+420)',
        phoneNumber: '',
        country: 'Česko',
        street: '',
        streetNumber: '',
        apartmentNumber: '',
        zipCode: '',
        city: '',
      },
      phonePrefixes: ['CZ (+420)', 'SK (+421)', 'PL (+48)'],
      countries: ['Česko', 'Slovensko', 'Polsko'],
      rules: {
        required: (value) => !!value || 'Toto pole je povinné.',
        phone: (value) => /^\d{9,15}$/.test(value) || 'Zadejte platné telefonní číslo.',
        zipCode: (value) => /^\d{3} ?\d{2}$/.test(value) || 'Zadejte platné PSČ (např. 148 00).',
        numeric: (value) => /^\d+$/.test(value) || 'Toto pole musí obsahovat pouze čísla.',
      },
    }
  },
  methods: {
    async submitForm() {
      if (this.$refs.form.validate()) {
        try {
          const paymentData = {
            firstName: this.form.firstName,
            lastName: this.form.lastName,
            company: this.form.company,
            phone: this.form.phonePrefix + this.form.phoneNumber,
            country: this.form.country,
            street: this.form.street,
            streetNumber: this.form.streetNumber,
            apartmentNumber: this.form.apartmentNumber,
            zipCode: this.form.zipCode,
            city: this.form.city,
          }

          const { data } = await this.$apollo.mutate({
            mutation: subscriptionRenew,
            variables: {
              paymentData,
              deviceId: this.device.id,
            },
          })

          if (data?.initTransaction) {
            this.$toast.success(this.$t('global.subscriptionRenewalSuccess'))
            this.onClose()
          } else {
            this.$toast.error(this.$t('global.subscriptionRenewalError'))
          }
        } catch (error) {
          console.error('Error renewing subscription:', error)
          this.$toast.error(this.$t('global.subscriptionRenewalError'))
        }
      } else {
        this.$toast.error(this.$t('global.fillRequiredFields'))
      }
    },
  },
  props: {
    value: Boolean,
    device: {
      type: Object,
      required: true,
    },
    onClose: Function,
  },
}
</script>

<style scoped>
.v-container {
  max-width: 600px;
}

form {
  padding: 16px;
}
</style>
