<template>
  <div class="pa-6">
    <h1 class="mb-5">Subscription Keys</h1>

    <v-autocomplete
      v-model="selectedCompany"
      :items="filteredCompanies"
      item-text="name"
      item-value="id"
      label="Select Company"
      class="mb-6"
      required
      :loading="$apollo.loading"
      :search-input.sync="search"
      return-object
      clearable
    ></v-autocomplete>

    <v-card class="mb-6 pa-4">
      <h2>Create New Subscription Key</h2>
      <v-form @submit.prevent="createSubscriptionKey">
        <v-container>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="newKey.price"
                label="Price"
                type="number"
                min="0.01"
                step="1"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-select
                v-model="newKey.currency"
                :items="['CZK', 'USD', 'EUR']"
                label="Currency"
                required
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="newKey.subscriptionLength"
                label="Length (months)"
                type="number"
                min="1"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-menu
                v-model="datePicker"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formattedDate"
                    label="Active From"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="newKey.activeFrom"
                  :min="minDate"
                  @input="datePicker = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-btn type="submit" color="primary" class="mt-4">Create</v-btn>
        </v-container>
      </v-form>
    </v-card>

    <h2>Subscription Keys</h2>

    <v-card class="mb-6 pa-4">
      <div v-if="isLoading" class="d-flex justify-center mt-8">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>
      <v-data-table v-else :items="subscriptionKeys" :headers="headers" item-value="id">
        <template v-slot:item.activeFrom="{ item }">
          {{ formatDate(item.activeFrom) }}
        </template>
        <template v-slot:item.isActive="{ item }">
          <v-icon v-if="isActive(item)">mdi-check</v-icon>
        </template>
        <template v-slot:item.delete="{ item }">
          <v-btn color="error" small :disabled="!canDelete(item)" @click="handleDelete(item)">
            Delete
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { subscriptionKeys } from '@/graphql/query/subscriptionKeys'
import { subscriptionKeyCreate } from '@/graphql/mutations/subscriptionKeyCreate'
import { companies } from '@/graphql/query/companies'
import { subscriptionKeyDelete } from '../../graphql/mutations/subscriptionKeyDelete'

export default {
  data() {
    return {
      selectedCompany: null,
      isLoading: false,
      companies: [],
      companiesQueryInput: {
        search: null,
        first: 200,
        after: null,
        last: null,
        before: null,
      },
      search: '',
      datePicker: false,
      newKey: {
        price: '',
        currency: 'CZK',
        subscriptionLength: '',
        activeFrom: '',
      },
      subscriptionKeys: [],
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Price', value: 'price' },
        { text: 'Currency', value: 'currency' },
        { text: 'Length (months)', value: 'subscriptionLength' },
        { text: 'Active From', value: 'activeFrom' },
        { text: 'Is Active', value: 'isActive' },
        { text: 'Delete', value: 'delete', sortable: false },
      ],
    }
  },
  computed: {
    formattedDate() {
      return this.newKey.activeFrom ? new Date(this.newKey.activeFrom).toLocaleDateString() : ''
    },
    companiesData() {
      if (!this.companies || !this.companies.edges) {
        return []
      }
      return this.companies.edges.map((edge) => edge.node)
    },
    filteredCompanies() {
      if (!this.search) {
        return this.companiesData
      }
      const searchText = this.search.toLowerCase()
      return this.companiesData.filter((company) => company.name.toLowerCase().includes(searchText))
    },
    latestActiveFrom() {
      if (!this.subscriptionKeys.length) return null
      return Math.max(...this.subscriptionKeys.map((key) => new Date(key.activeFrom)))
    },
    minDate() {
      const today = new Date()
      return today.toISOString().split('T')[0]
    },
  },
  apollo: {
    companies: {
      query: companies,
      variables() {
        return this.companiesQueryInput
      },
    },
    subscriptionKeys: {
      query: subscriptionKeys,
      variables() {
        return {
          companyId: this.selectedCompany?.id,
        }
      },
      skip() {
        return !this.selectedCompany
      },
      update(data) {
        // Sort subscription keys by activeFrom date
        const keys = [...(data.subscriptionKeys || [])]
        return keys.sort((a, b) => parseInt(b.activeFrom) - parseInt(a.activeFrom)) // Sort in descending order
      },
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return ''
      try {
        // Handle Unix timestamp in milliseconds
        const d = typeof date === 'number' ? new Date(date) : new Date(parseInt(date))
        if (isNaN(d.getTime())) {
          console.error('Invalid date:', date)
          return ''
        }

        // Format as dd-MM-yyyy
        const day = String(d.getDate()).padStart(2, '0')
        const month = String(d.getMonth() + 1).padStart(2, '0')
        const year = d.getFullYear()

        return `${day}-${month}-${year}`
      } catch (error) {
        console.error('Error formatting date:', error)
        return ''
      }
    },
    async handleDelete(item) {
      try {
        const input = {
          id: item.id,
        }
        const { data } = await this.$apollo.mutate({
          mutation: subscriptionKeyDelete,
          variables: { input },
        })

        if (data.subscriptionKeyDelete.success) {
          // Refresh the subscription keys list
          await this.$apollo.queries.subscriptionKeys.refetch()

          this.$toast.success('Subscription key deleted successfully')
        } else {
          this.$toast.error('Failed to delete subscription key')
        }
      } catch (error) {
        console.error('Error deleting subscription key:', error)
        this.$toast.error('Error deleting subscription key')
      }
      // Placeholder for delete logic
    },
    canDelete(item) {
      const now = new Date()

      const activeFrom = new Date(parseInt(item.activeFrom))
      return activeFrom > now
    },
    async createSubscriptionKey() {
      if (!this.selectedCompany) {
        this.$toast.error('Please select a company first')
        return
      }

      const price = parseFloat(this.newKey.price)
      if (!price || price <= 0) {
        this.$toast.error('Price must be greater than 0')
        return
      }

      const newDate = new Date(this.newKey.activeFrom)
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      newDate.setHours(0, 0, 0, 0)

      if (newDate < today) {
        this.$toast.error('Active From date cannot be in the past')
        return
      }

      if (this.latestActiveFrom && newDate <= this.latestActiveFrom) {
        this.$toast.error(
          'Active From date must be after the latest existing subscription key date',
        )
        return
      }

      if (parseFloat(this.newKey.subscriptionLength) <= 0) {
        this.$toast.error('Length must be greater than 0')
        return
      }

      try {
        const input = {
          companyId: this.selectedCompany.id,
          price: parseFloat(this.newKey.price),
          currency: this.newKey.currency,
          subscriptionLength: parseFloat(this.newKey.subscriptionLength),
          activeFrom: this.newKey.activeFrom,
        }

        const { data } = await this.$apollo.mutate({
          mutation: subscriptionKeyCreate,
          variables: { input },
        })

        if (data.subscriptionKeyCreate.success) {
          // Reset form
          this.newKey = {
            price: '',
            currency: 'CZK',
            subscriptionLength: '',
            activeFrom: '',
          }
          this.datePicker = false

          // Refresh the subscription keys list
          await this.$apollo.queries.subscriptionKeys.refetch()

          this.$toast.success('Subscription key created successfully')
        } else {
          this.$toast.error('Failed to create subscription key')
        }
      } catch (error) {
        console.error('Error creating subscription key:', error)
        this.$toast.error('Error creating subscription key')
      }
    },
    isActive(item) {
      const itemDate = new Date(parseInt(item.activeFrom))
      const currentDate = new Date()

      // Check if this is the latest active key
      const isLatestActive = this.subscriptionKeys.every((key) => {
        const keyDate = new Date(parseInt(key.activeFrom))
        return keyDate <= itemDate || keyDate > currentDate
      })

      console

      return isLatestActive && itemDate <= currentDate
    },
  },
}
</script>

<style scoped></style>
