import gql from 'graphql-tag'

export const subscriptionKeys = gql`
  query subscriptionKeys($companyId: ID!) {
    subscriptionKeys(companyId: $companyId) {
      id
      price
      currency
      subscriptionLength
      activeFrom
    }
  }
`
