import { permissions } from '@/config/permissions'
import { eligibilities } from '@/config/eligibilities'
import { userDeviceAccessRoles } from '@/config/userDeviceAccessRoles'

export function tabAccessibilityChecker(
  tabType,
  permissionChecker,
  userAccessType,
  isMyDevicesView,
) {
  const isTabAccessible = {
    COMPOSED: () => true,
    DISPLAY: () => true,
    INSTALLATION_SITE: (permissionChecker) =>
      permissionChecker([permissions.FULL_CUSTOMER_DATA], []) ||
      permissionChecker([permissions.RESTRICTED_CUSTOMER_DATA], []),
    GRAPHS: (permissionChecker) => permissionChecker(permissions.OPERATIONAL_HISTORY, []),
    HISTORY: (permissionChecker) => permissionChecker(permissions.OPERATIONAL_HISTORY, []),
    TIMEPLAN: () => true,
    GALLERY: (permissionChecker) => permissionChecker([], eligibilities.GALLERY_VIEW),
    USER_ACCESSES: (permissionChecker, userAccessType, isMyDevicesView) =>
      (isMyDevicesView && userAccessType === userDeviceAccessRoles.DEVICE_ADMIN) ||
      permissionChecker(permissions.USER_MANAGEMENT, null),
    SERVICE_HISTORY: (permissionChecker) =>
      permissionChecker(permissions.OPERATIONAL_HISTORY, eligibilities.SERVICE_HISTORY),
    OPERATION_COUNTERS: (permissionChecker) =>
      permissionChecker(permissions.CONFIGURATION_CHANGES, eligibilities.OPERATION_COUNTERS),
    SERVICE_ORDERS: (permissionChecker) =>
      permissionChecker(permissions.OPERATIONAL_HISTORY, eligibilities.SERVICE_ORDERS),
    NOTIFICATION_SETTINGS: (permissionChecker, userAccessType, isMyDevicesView) =>
      (isMyDevicesView &&
        userAccessType === userDeviceAccessRoles.DEVICE_ADMIN &&
        permissionChecker([], eligibilities.NOTIFICATION_DISPATCHER)) ||
      (!isMyDevicesView &&
        permissionChecker(permissions.USER_MANAGEMENT, eligibilities.NOTIFICATION_DISPATCHER)),
    CHARGING_HISTORY: (permissionChecker) => permissionChecker([], eligibilities.CHARGING_HISTORY),
    SUBSCRIPTION: () => true,
  }

  return isTabAccessible[tabType](permissionChecker, userAccessType, isMyDevicesView)
}
