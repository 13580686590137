<template>
  <div class="time-schedule-tab-wrapper">
    <div v-if="$apollo.loading" class="d-flex justify-center mt-8">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <div v-else>
      <div v-if="activeTimeScheduleNoMatch || !activeWeeklyTimeSchedule">
        <div class="d-flex align-center justify-space-between flex-wrap">
          <h1>{{ $t('global.schedule') }}</h1>
          <div class="action-btn-wrapper">
            <v-btn
              height="27"
              depressed
              :disabled="isRunningServiceSequence"
              @click="openWeeklyScheduleModal(null, viewModeEnum.EDIT)"
              >{{ $t('device.timeSchedule.showWeeklyTimeSchedules') }}</v-btn
            >
            <v-btn
              v-if="!isErrorInConfig"
              height="27"
              depressed
              :disabled="isRunningServiceSequence"
              @click="dailySchedulesModalOpen = true"
              >{{ $t('device.timeSchedule.showDailyTimeSchedules') }}</v-btn
            >
            <div class="vertical-line"></div>
            <!-- <v-btn
              v-if="
                permissionChecker(
                  permissions.CONFIGURATION_CHANGES,
                  eligibilities.TIME_SCHEDULE_EDIT,
                ) && !isErrorInConfig
              "
              height="27"
              depressed
              :disabled="isRunningServiceSequence"
              @click="openWeeklyScheduleModal(null, viewModeEnum.NEW)"
              >{{ $t('global.newWeeklySchedule') }}</v-btn
            > -->
          </div>
        </div>
        <div v-if="!isErrorInConfig" class="text-center py-12">
          <p v-if="activeTimeScheduleNoMatch">
            <b>{{ $t('device.timeSchedule.activeTimeScheduleNoMatch') }}</b
            ><br />{{ $t('device.timeSchedule.pleaseActivate') }}
          </p>
          <p v-else>
            <b>{{ $t('device.timeSchedule.noActiveTimeSchedule') }}</b>
          </p>
          <v-btn
            v-if="
              permissionChecker(permissions.DEVICE_CONTROL, eligibilities.TIME_SCHEDULE_SELECTION)
            "
            color="primary"
            depressed
            :disabled="isRunningServiceSequence"
            @click="openWeeklyScheduleModal(null, viewModeEnum.VIEW)"
            >{{ $t('device.timeSchedule.selectSchedule') }}</v-btn
          >
        </div>
        <div class="text-center py-12">
          <p v-if="isErrorInConfig">{{ $t('global.errorInTimeScheduleConfiguration') }}</p>
        </div>
      </div>
      <template v-else>
        <div v-if="!isErrorInConfig">
          <h2 class="mb-1">{{ $t('global.activeWeeklySchedule') }}</h2>
          <div class="d-flex align-center justify-space-between flex-wrap">
            <div class="d-flex flex-wrap align-center mb-2 mr-5" style="gap: 10px">
              <v-btn
                height="27"
                text
                :disabled="isRunningServiceSequence"
                @click="
                  permissionChecker(
                    permissions.DEVICE_CONTROL,
                    eligibilities.TIME_SCHEDULE_SELECTION,
                  )
                    ? openWeeklyScheduleModal(activeWeeklyTimeSchedule.id, viewModeEnum.EDIT)
                    : null
                "
                ><h1>{{ activeWeeklyTimeSchedule.name }}</h1></v-btn
              >

              <!-- <span class="schedule-label mr-2">{{
                activeWeeklyTimeSchedule.isGlobal
                  ? $t('global.predefinedSchedule')
                  : $t('global.mySchedule')
              }}</span> -->
            </div>
            <div class="action-btn-wrapper mb-2">
              <v-btn
                height="27"
                depressed
                :disabled="isRunningServiceSequence"
                @click="openWeeklyScheduleModal(activeWeeklyTimeSchedule.id, viewModeEnum.EDIT)"
                >{{
                  permissionChecker(
                    permissions.DEVICE_CONTROL,
                    eligibilities.TIME_SCHEDULE_SELECTION,
                  )
                    ? $t('device.timeSchedule.changeSchedule')
                    : $t('device.timeSchedule.showWeeklyTimeSchedules')
                }}</v-btn
              >
              <v-btn
                height="27"
                depressed
                :disabled="isRunningServiceSequence"
                @click="dailySchedulesModalOpen = true"
                >{{ $t('device.timeSchedule.showDailyTimeSchedules') }}</v-btn
              >
              <div class="vertical-line"></div>
              <!-- <v-btn
                v-if="
                  permissionChecker(
                    permissions.CONFIGURATION_CHANGES,
                    eligibilities.TIME_SCHEDULE_EDIT,
                  )
                "
                height="27"
                depressed
                :disabled="isRunningServiceSequence"
                @click="openWeeklyScheduleModal(null, viewModeEnum.NEW)"
                >{{ $t('global.newWeeklySchedule') }}</v-btn
              > -->
            </div>
          </div>
          <div class="daily-schedules" :class="{ dense: $vuetify.breakpoint.smAndDown }">
            <div class="connectors">
              <div class="upper"></div>
              <div class="lower"></div>
            </div>
            <div
              v-for="entry of activeWeeklyTimeScheduleEntriesSorted"
              :key="`${entry.dailySchedule.id}-lines`"
              class="connectors"
            >
              <div class="upper"></div>
              <div class="lower"></div>
            </div>
            <h2 class="mb-1 mt-3">{{ $t('global.dailySchedules') }}</h2>
            <section
              v-for="(entry, index) of activeWeeklyTimeScheduleEntriesSorted"
              :key="entry.dailySchedule.id"
              class="daily-entry-wrapper"
              :class="{ light: index !== 0 }"
            >
              <div class="entry-header">
                <div class="d-flex align-center">
                  <h1 class="mr-4">{{ dailyScheduleMap.get(entry.dailySchedule.id).name }}</h1>
                  <span v-if="index === 0" class="today-tag mr-2">{{ $t('global.today') }}</span>
                  <!-- <span class="schedule-label mr-2">{{
                    dailyScheduleMap.get(entry.dailySchedule.id).isGlobal
                      ? $t('global.predefinedSchedule')
                      : $t('global.mySchedule')
                  }}</span> -->
                </div>
                <div class="d-flex align-center">
                  <span class="repeat-span">{{ $t('global.repeat') }}:</span>
                  <span class="repeated-days-list">{{
                    entry.days.length === 7
                      ? $t('global.daily')
                      : entry.days.map((day) => $t(`global.daysOfWeekShort.${day}`)).join(', ')
                  }}</span>
                </div>
              </div>
              <DailyScheduleOverview
                :timeSchedule="dailyScheduleMap.get(entry.dailySchedule.id)"
                :timeScheduleConfig="timeScheduleConfig"
                :showCurrentTime="index === 0"
                @dailyScheduleErrorConditionMet="isErrorInConfigMethod"
              />
            </section>
          </div>
        </div>
        <div v-else class="text-center py-12">
          <p>{{ $t('global.errorInTimeScheduleConfiguration') }}</p>
        </div>
      </template>

      <v-overlay
        absolute
        :opacity="0.5"
        :value="Boolean(isActivatingTimeScheduleId)"
        style="border-radius: 4px"
        class="d-flex align-center justify-center"
      >
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </v-overlay>

      <WeeklyTimeSchedulesModal
        v-if="deviceWithSchedules"
        v-model="weeklySchedulesModalOpen"
        :device="device"
        :weeklyTimeSchedules="deviceWithSchedules.weeklyTimeSchedules"
        :dailyTimeSchedules="deviceWithSchedules.dailyTimeSchedules"
        :timeScheduleConfig="timeScheduleConfig"
        :createMethod="createWeeklyTimeSchedule"
        :updateMethod="updateWeeklyTimeSchedule"
        :deleteMethod="deleteWeeklyTimeSchedule"
        :dailyCreateMethod="createDailyTimeSchedule"
        :dailyUpdateMethod="updateDailyTimeSchedule"
        :editable="
          permissionChecker(permissions.CONFIGURATION_CHANGES, eligibilities.TIME_SCHEDULE_EDIT)
        "
        :canActivate="
          permissionChecker(permissions.DEVICE_CONTROL, eligibilities.TIME_SCHEDULE_SELECTION)
        "
        :activeTimeScheduleId="activeTimeScheduleId"
        :isActivatingTimeScheduleId.sync="isActivatingTimeScheduleId"
        :preselectScheduleId="preselectWeeklyScheduleId"
        :openInViewMode="openWeeklyScheduleModalInViewMode"
        @isErrorInConfig="isErrorInConfigMethod"
      />
      <DailyTimeSchedulesModal
        v-if="deviceWithSchedules"
        v-model="dailySchedulesModalOpen"
        :dailyTimeSchedules="deviceWithSchedules.dailyTimeSchedules"
        :weeklyTimeSchedules="deviceWithSchedules.weeklyTimeSchedules"
        :timeScheduleConfig="timeScheduleConfig"
        :editable="
          permissionChecker(permissions.CONFIGURATION_CHANGES, eligibilities.TIME_SCHEDULE_EDIT)
        "
        :createMethod="createDailyTimeSchedule"
        :updateMethod="updateDailyTimeSchedule"
        :deleteMethod="deleteDailyTimeSchedule"
      />
    </div>
  </div>
</template>
<script>
import WeeklyTimeSchedulesModal from '@/components/TimeSchedules/WeeklyTimeSchedulesModal'
import DailyTimeSchedulesModal from '@/components/TimeSchedules/DailyTimeSchedulesModal'
import DailyScheduleOverview from '@/components/TimeSchedules/DailyScheduleOverview'
import { deviceTimeSchedules } from '@/graphql/query/deviceTimeSchedules'
import { deviceTimeScheduleConfig } from '@/graphql/query/deviceTimeScheduleConfig'
import produce from 'immer'
import {
  dailyTimeScheduleCreate,
  dailyTimeScheduleUpdate,
  dailyTimeScheduleDelete,
} from '@/graphql/mutations/dailyTimeSchedule'
import { permissions } from '@/config/permissions'
import { eligibilities } from '@/config/eligibilities'
import { jsDays } from '@/config/daysOfWeek'
import { viewModeEnum } from '../../TimeSchedules/WeeklyTimeSchedulesModal.vue'
import {
  weeklyTimeScheduleCreate,
  weeklyTimeScheduleUpdate,
  weeklyTimeScheduleDelete,
} from '@/graphql/mutations/weeklyTimeSchedule'
import { EventBus } from '@/helper/EventBus'

export default {
  name: 'TimeScheduleTab',
  components: {
    WeeklyTimeSchedulesModal,
    DailyTimeSchedulesModal,
    DailyScheduleOverview,
  },
  props: {
    device: Object,
    permissionChecker: Function,
    isRunningServiceSequence: Boolean,
  },
  data() {
    return {
      isErrorInConfig: false,
      activeTimeScheduleNoMatch: false,
      activeTimeScheduleId: null,
      isActivatingTimeScheduleId: null,
      activationTimeoutId: null,
      weeklySchedulesModalOpen: false,
      dailySchedulesModalOpen: false,
      openWeeklyScheduleModalInViewMode: null,
      preselectWeeklyScheduleId: null,
    }
  },
  apollo: {
    deviceWithSchedules: {
      query: deviceTimeSchedules,
      errorPolicy: 'all',
      variables() {
        return {
          deviceId: this.device.id,
        }
      },
      update(data) {
        if (
          this.isActivatingTimeScheduleId &&
          this.activeTimeScheduleId !== data.device.activeTimeSchedule?.id
        ) {
          this.isActivatingTimeScheduleId = null
          clearTimeout(this.activationTimeoutId)
          this.$toast.success(this.$t('device.timeSchedule.setActiveTimeScheduleSuccess'))
        }
        this.activeTimeScheduleId = data.device.activeTimeSchedule?.id

        return data.device
      },
      result({ errors }) {
        this.activeTimeScheduleNoMatch = errors
          ?.map((e) => e.message)
          ?.includes('DEVICE_ACTIVE_TIME_SCHEDULE_QUERY_NO_MATCH')
      },
    },
    timeScheduleConfig: {
      query: deviceTimeScheduleConfig,
      fetchPolicy: 'cache-first',
      variables() {
        return {
          deviceId: this.device.id,
          lang: this.$i18n.locale,
        }
      },
      update(data) {
        return data.device.timeScheduleConfig
      },
    },
  },
  mounted() {
    EventBus.$on('refetchDevice', this.refetchDevice)
  },
  beforeDestroy() {
    EventBus.$off('refetchDevice', this.refetchDevice)
  },
  methods: {
    refetchDevice() {
      this.$apollo.queries.deviceWithSchedules.refetch()
      this.$apollo.queries.timeScheduleConfig.refetch()
    },
    isErrorInConfigMethod() {
      this.isErrorInConfig = true
    },
    async createWeeklyTimeSchedule(timeSchedule, timeScheduleName) {
      try {
        await this.$apollo.mutate({
          mutation: weeklyTimeScheduleCreate,
          variables: {
            input: {
              deviceId: this.device.id,
              name: timeScheduleName,
              schedule: timeSchedule,
              deviceType: this.device.type,
            },
          },
          // update cache
          update: (store, response) => {
            const query = {
              query: deviceTimeSchedules,
              variables: {
                deviceId: this.device.id,
              },
            }
            const data = store.readQuery(query)
            const newData = produce(data, (draft) => {
              draft.device.weeklyTimeSchedules.unshift(
                response.data.weeklyTimeScheduleCreate.weeklyTimeSchedule,
              )
            })
            store.writeQuery({
              ...query,
              data: newData,
            })
          },
        })
        this.$toast.success(this.$t('device.timeSchedule.createTimeScheduleSuccess'))
      } catch (error) {
        console.error(error)
        throw this.$t('device.timeSchedule.createTimeScheduleFailed')
      }
    },
    async updateWeeklyTimeSchedule(timeScheduleId, timeSchedule, timeScheduleName) {
      try {
        await this.$apollo.mutate({
          mutation: weeklyTimeScheduleUpdate,
          variables: {
            input: {
              weeklyTimeScheduleId: timeScheduleId,
              name: timeScheduleName,
              schedule: timeSchedule,
            },
          },
          refetchQueries: ['deviceTimeSchedules'],
        })
        this.$toast.success(this.$t('device.timeSchedule.updateTimeScheduleSuccess'))
      } catch (error) {
        console.error(error)
        throw this.$t('device.timeSchedule.updateTimeScheduleFailed')
      }
    },
    async deleteWeeklyTimeSchedule(timeScheduleId) {
      try {
        await this.$apollo.mutate({
          mutation: weeklyTimeScheduleDelete,
          variables: {
            weeklyScheduleId: timeScheduleId,
          },
          // update cache
          update: (store, response) => {
            const query = {
              query: deviceTimeSchedules,
              variables: {
                deviceId: this.device.id,
              },
            }
            const data = store.readQuery(query)
            const newData = produce(data, (draft) => {
              const indexToRemove = draft.device.weeklyTimeSchedules.findIndex(
                (schedule) => schedule.id === response.data.weeklyTimeScheduleDelete.deletedId,
              )
              draft.device.weeklyTimeSchedules.splice(indexToRemove, 1)
            })
            store.writeQuery({
              ...query,
              data: newData,
            })
          },
        })
        this.$toast.success(this.$t('device.timeSchedule.deleteTimeScheduleSuccess'))
      } catch (error) {
        console.error(error)
        throw this.$t('device.timeSchedule.deleteTimeScheduleFailed')
      }
    },
    async createDailyTimeSchedule(newSchedule, newName) {
      try {
        const response = await this.$apollo.mutate({
          mutation: dailyTimeScheduleCreate,
          variables: {
            input: {
              deviceId: this.device.id,
              name: newName,
              schedule: newSchedule,
              deviceType: this.device.type,
            },
          },
          // update cache
          update: (store, response) => {
            const query = {
              query: deviceTimeSchedules,
              variables: {
                deviceId: this.device.id,
              },
            }
            const data = store.readQuery(query)
            const newData = produce(data, (draft) => {
              draft.device.dailyTimeSchedules.unshift(
                response.data.dailyTimeScheduleCreate.dailyTimeSchedule,
              )
            })
            store.writeQuery({
              ...query,
              data: newData,
            })
          },
        })
        this.$toast.success(this.$t('device.timeSchedule.createTimeScheduleSuccess'))

        return response.data.dailyTimeScheduleCreate.dailyTimeSchedule.id
      } catch (error) {
        console.error(error)
        throw this.$t('device.timeSchedule.createTimeScheduleFailed')
      }
    },
    async updateDailyTimeSchedule(newSchedule, newName, dailyTimeScheduleId) {
      try {
        await this.$apollo.mutate({
          mutation: dailyTimeScheduleUpdate,
          variables: {
            input: {
              dailyTimeScheduleId,
              name: newName,
              schedule: newSchedule,
            },
          },
          refetchQueries: ['deviceTimeSchedules'],
        })
        this.$toast.success(this.$t('device.timeSchedule.updateTimeScheduleSuccess'))
      } catch (error) {
        console.error(error)
        throw this.$t('device.timeSchedule.updateTimeScheduleFailed')
      }
    },
    async deleteDailyTimeSchedule(dailyScheduleId) {
      try {
        await this.$apollo.mutate({
          mutation: dailyTimeScheduleDelete,
          variables: {
            dailyScheduleId,
          },
          refetchQueries: ['deviceTimeSchedules'],
        })
        this.$toast.success(this.$t('device.timeSchedule.deleteTimeScheduleSuccess'))
      } catch (error) {
        console.error(error)
        throw this.$t('device.timeSchedule.deleteTimeScheduleFailed')
      }
    },
    openWeeklyScheduleModal(id, viewMode) {
      this.preselectWeeklyScheduleId = id
      this.openWeeklyScheduleModalInViewMode = viewMode
      this.weeklySchedulesModalOpen = true
    },
  },
  computed: {
    permissions() {
      return permissions
    },
    eligibilities() {
      return eligibilities
    },
    activeWeeklyTimeSchedule() {
      return this.deviceWithSchedules?.weeklyTimeSchedules.find(
        (schedule) => schedule.id === this.activeTimeScheduleId,
      )
    },
    activeWeeklyTimeScheduleEntriesSorted() {
      if (!this.activeWeeklyTimeSchedule) {
        return null
      }
      const currentDay = jsDays[new Date().getDay()]
      const weeklyScheduleCopy = [...this.activeWeeklyTimeSchedule.weeklySchedule]
      const activeDailyScheduleIndex = this.activeWeeklyTimeSchedule.weeklySchedule.findIndex(
        (entry) => entry.days.includes(currentDay),
      )
      if (activeDailyScheduleIndex !== 0) {
        const activeEntry = weeklyScheduleCopy.splice(activeDailyScheduleIndex, 1)
        weeklyScheduleCopy.unshift(...activeEntry)
      }

      return weeklyScheduleCopy
    },
    dailyScheduleMap() {
      const map = new Map()
      this.deviceWithSchedules?.dailyTimeSchedules.forEach((schedule) => {
        map.set(schedule.id, schedule)
      })
      return map
    },
    viewModeEnum() {
      return viewModeEnum
    },
  },
  watch: {
    isActivatingTimeScheduleId() {
      if (this.isActivatingTimeScheduleId) {
        this.$apollo.queries.deviceWithSchedules.startPolling(1000)
        this.activationTimeoutId = setTimeout(() => {
          if (this.isActivatingTimeScheduleId) {
            this.isActivatingTimeScheduleId = null
            this.$toast.error(this.$t('device.timeSchedule.setActiveTimeScheduleFailed'))
          }
        }, 10000)
      } else {
        this.$apollo.queries.deviceWithSchedules.stopPolling()
      }
    },
  },
}
</script>
<style lang="less" scoped>
@import '~@/assets/less/variables.less';
@import '~@/components/TimeSchedules/timeSchedule.less';

.time-schedule-tab-wrapper {
  h1 {
    font-size: 24px;
  }

  h2 {
    font-weight: 400;
    font-size: 14px;
    color: #8089ac;
  }

  .action-btn-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;

    .vertical-line {
      width: 1px;
      align-self: stretch;
      background: black;
      opacity: 0.07;
    }
  }
}

.daily-schedules {
  display: grid;
  grid-template-columns: 60px 1fr;
  grid-auto-flow: row dense;

  &.dense {
    grid-template-columns: 25px 1fr;
  }

  .connectors {
    grid-column-start: 1;

    .circle {
      content: '';
      position: absolute;
      background: white;
      border-radius: 100%;
      width: 11px;
      height: 11px;
      border: 1px solid #e3e2e4;
    }

    .upper,
    .lower {
      height: 50%;
      border-left: 1px solid #f1f0f1;
    }

    .upper {
      position: relative;
      border-bottom: 1px solid #f1f0f1;
    }

    &:first-of-type .upper {
      border-bottom: none;

      &::before {
        .circle();
        top: -5.5px;
        left: -5.5px;
      }
    }

    &:nth-of-type(-n + 2) {
      .upper,
      .lower {
        border-color: #e3e2e4;
      }
    }

    &:nth-of-type(2) {
      .lower {
        border-color: #f1f0f1;
      }
      .upper::before {
        .circle();
        bottom: -5.5px;
        left: -5.5px;
      }
      .upper::after {
        .circle();
        bottom: -5.5px;
        right: -5.5px;
      }
    }

    &:last-of-type .lower {
      border: none;
    }
  }
}

.daily-entry-wrapper {
  border: 1px solid #e3e2e4;
  border-radius: 8px;
  margin-bottom: 9px;
  margin-top: 9px;

  &.light {
    opacity: 0.5;
  }

  .entry-header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0px 18px;
    margin-top: 17px;
  }

  .today-tag {
    color: white;
    background: #4caf50;
    border-radius: 6px;
    font-weight: 400;
    font-size: 13px;
    padding: 4.5px 10px;
    line-height: 15px;
  }

  h1 {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
  }

  .repeat-span {
    font-size: 14px;
    margin-right: 3px;
  }

  .repeated-days-list {
    font-size: 14px;
    font-weight: 500;
  }
}
</style>
