import gql from 'graphql-tag'

export const subscriptionRenew = gql`
  mutation SubscriptionRenew($paymentData: PaymentDataInput!, $deviceId: ID!) {
    initTransaction(paymentData: $paymentData, deviceId: $deviceId) {
      id
      status
      transId
      subscriptionId
      createdAt
      updatedAt
    }
  }
`
