<template>
  <div ref="wrapper">
    <RichTextEditor ref="richTextEditor" :usersAvailableToMention="usersAvailableToMention" />
    <div class="text-right mb-7">
      <v-btn @click="createEntry()" color="primary" depressed>{{ $t('global.add') }}</v-btn>
    </div>
    <div
      v-if="$apollo.loading && !serviceHistoryResult && !serviceHistoryEntryTypes"
      class="d-flex justify-center mt-8"
    >
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <div v-else>
      <div class="filter d-flex align-center mb-4" style="gap: 30px">
        <span>{{ $t('global.filter') }}:</span>
        <v-checkbox
          v-for="entryType of serviceHistoryEntryTypes"
          :key="entryType"
          :input-value="filteredEntryTypes.includes(entryType)"
          @change="onEntryTypeFilterChange(entryType, $event)"
          :label="$t(`global.serviceHistoryEntryTypes.${entryType}`)"
          hide-details
        ></v-checkbox>
      </div>
      <div v-for="entry of serviceHistory" class="entry" :key="entry.id">
        <!-- Event code notification -->
        <template v-if="entry.eventCode">
          <span class="metadata"
            ><b class="mr-1">{{ $t('global.systemEvent') }}</b>
            {{ formatToDateAndMinutes(entry.createdAt) }}</span
          >
          <div class="d-flex align-center mt-2 event-code-notification">
            {{
              entry.action === 'CREATED'
                ? $t('global.newErrorNotification')
                : $t('global.errorMessageClosed')
            }}:
            <ReportBadge
              :text="entry.eventCode.description"
              :severity="entry.eventCode.severity"
              :clickable="false"
              :deviceId="null"
              class="ml-2"
            />
          </div>
        </template>
        <!-- New operating cycle text -->
        <template v-else-if="entry.cycleStart">
          <span class="metadata"
            ><b class="mr-1">{{ $t('global.operatingCycle') }}</b>
            {{ formatToDateAndMinutes(entry.createdAt) }}</span
          >
          <p class="new-operating-cycle-text mb-0">
            <span class="">{{ $t('global.newOperatingCycle') }}: </span>
            <span class="metadata-inside">{{ getAuthorName(entry) }}</span>
            <br />
            {{ $t('global.cycleStartDate') }}: {{ formatToDate(entry.cycleStart) }}<br />{{
              $t('global.hourCounter')
            }}: {{ secondsToHours(entry.timeCounter) }}<br />{{ $t('global.startCounter') }}:
            {{ entry.startCounter }}<br />{{ $t('global.equipmentId') }}: {{ entry.equipmentId
            }}<br />{{ $t('global.equipmentType') }}: {{ entry.equipmentType }}
          </p>
          <span
            v-if="entry.note"
            class="rich-text d-block mt-3"
            v-sanitized-html="entry.note"
          ></span>
        </template>
        <template v-else-if="entry.emailSentTo">
          <span class="metadata"
            ><b>{{ $t('global.user') }}</b> {{ getAuthorName(entry) }},
            {{ formatToDateAndMinutes(entry.createdAt) }},
            <b>{{ $t('global.contactedServicePartner') }}</b>
          </span>
          <span class="rich-text" v-sanitized-html="entry.text"></span>
          <div class="metadata mt-3">
            <span>{{ $t('global.emailSentTo') }}:</span>
            <ul>
              <li v-for="email of entry.emailSentTo" :key="email">{{ email }}</li>
            </ul>
          </div>
        </template>
        <template v-else>
          <!-- New user message -->

          <span class="metadata"
            ><b class="mr-1">{{ $t('global.userMessage') }}</b>
            {{ formatToDateAndMinutes(entry.createdAt) }}</span
          >
          <span class="metadata-inside">{{ $t('global.newMessage') }}: </span>
          <span class="metadata-inside">{{ getAuthorName(entry) }}</span>
          <span class="rich-text" v-sanitized-html="entry.text"></span>
        </template>
      </div>
      <div>
        <div v-if="serviceHistoryError" class="d-flex justify-center align-center">
          <span class="mr-2">{{ $t('global.queryFailed') }}</span>
          <v-btn @click="retryServiceHistory()" color="primary" depressed>{{
            $t('global.retry')
          }}</v-btn>
        </div>
      </div>
      <div>
        <div v-if="$apollo.queries.serviceHistoryResult.loading" class="d-flex justify-center">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </div>
        <v-btn v-if="endCursorToLoad" @click="scrollToTop()" outlined class="float-right"
          ><v-icon class="mr-2">mdi-format-vertical-align-top</v-icon
          >{{ $t('global.backToTop') }}</v-btn
        >
      </div>
    </div>
  </div>
</template>
<script>
import format from 'date-fns/format'
import { serviceHistory } from '@/graphql/query/serviceHistory'
import { serviceHistoryEntryTypeEnumValues } from '@/graphql/query/serviceHistoryEntryTypeEnumValues'
import { usersWithAccessToServiceHistory } from '@/graphql/query/usersWithAccessToServiceHistory'
import { serviceHistoryUserNoteCreate } from '@/graphql/mutations/serviceHistoryUserNoteCreate'
import RichTextEditor from '@/components/RichTextEditor/RichTextEditor'
import ReportBadge from '@/components/UI/ReportBadge'
import throttle from 'lodash/throttle'

export default {
  name: 'ServiceHistoryTab',
  components: {
    ReportBadge,
    RichTextEditor,
  },
  props: {
    tabConfig: Object,
    device: Object,
    deviceDetailWrapper: HTMLDivElement,
    active: Boolean,
  },
  data() {
    return {
      pageSize: 10,
      endCursor: null,
      endCursorToLoad: null,
      hasNextPage: false,
      serviceHistory: [],
      filteredEntryTypes: [],
      serviceHistoryError: null,
    }
  },
  mounted() {
    this.deviceDetailWrapper.addEventListener('scroll', this.scrollHandler)
  },
  beforeDestroy() {
    this.deviceDetailWrapper.removeEventListener('scroll', this.scrollHandler)
    this.editor?.destroy()
  },
  apollo: {
    serviceHistoryResult: {
      query: serviceHistory,
      variables() {
        return {
          deviceId: this.device.id,
          entryTypes: this.filteredEntryTypes,
          first: this.pageSize,
          after: this.endCursorToLoad,
          lang: this.$i18n.locale,
          language: this.$i18n.locale,
        }
      },
      update(data) {
        const newData = data.device.serviceHistory.edges.map(
          (serviceHistoryEntryEdge) => serviceHistoryEntryEdge.node,
        )
        this.endCursor = data.device.serviceHistory.pageInfo.endCursor
        this.hasNextPage = data.device.serviceHistory.pageInfo.hasNextPage
        this.serviceHistory.push(...newData)
        return newData
      },
      error(errors) {
        this.serviceHistoryError = errors
      },
      skip() {
        return !this.serviceHistoryEntryTypes
      },
    },
    serviceHistoryEntryTypes: {
      query: serviceHistoryEntryTypeEnumValues,
      update(data) {
        console.log(data)
        const entryTypes = data.__type.enumValues.map((value) => value.name)
        this.filteredEntryTypes = [...entryTypes]
        return entryTypes
      },
    },
    usersAvailableToMention: {
      query: usersWithAccessToServiceHistory,
      variables() {
        return {
          deviceId: this.device.id,
        }
      },
      update(data) {
        return data.device.usersWithAccessToServiceHistory
      },
    },
  },
  methods: {
    scrollHandler: throttle(function (event) {
      // scrolled to the end of the page
      if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 2) {
        // -2 sometimes condition wasn't working
        if (this.hasNextPage && this.serviceHistory.length) {
          this.loadMore()
        }
      }
    }, 100),
    loadMore() {
      this.endCursorToLoad = this.endCursor
    },
    async createEntry() {
      if (!this.$refs.richTextEditor.editor.isEmpty) {
        try {
          await this.$apollo.mutate({
            mutation: serviceHistoryUserNoteCreate,
            variables: {
              deviceId: this.device.id,
              text: this.$refs.richTextEditor.editor.getHTML(),
            },
          })
          this.$refs.richTextEditor.editor.commands.clearContent()
          if (this.filteredEntryTypes.includes('USER_NOTE')) {
            this.endCursorToLoad = null
            this.serviceHistory = []
            this.$apollo.queries.serviceHistoryResult.refetch()
          }
          this.$toast.success(this.$t('global.createServiceHistoryEntry.success'))
        } catch (error) {
          console.error(error)
          this.$toast.error(this.$t('global.createServiceHistoryEntry.failure'))
        }
      }
    },
    retryServiceHistory() {
      this.serviceHistoryError = null
      this.$apollo.queries.serviceHistoryResult.refetch()
    },
    getAuthorName(entry) {
      if (!entry.author) {
        return this.$t('global.anonymous')
      }
      return entry.author.firstName && entry.author.firstName.trim() !== ''
        ? `${entry.author.firstName} ${entry.author.lastName}`
        : entry.author.email
    },
    formatToDateAndMinutes(dateString) {
      return format(new Date(dateString), 'dd.MM.y HH:mm')
    },
    formatToDate(dateString) {
      return format(new Date(dateString), 'd.M.Y')
    },
    onEntryTypeFilterChange(entryType, show) {
      const filterCopy = [...this.filteredEntryTypes]
      const index = this.filteredEntryTypes.findIndex((et) => et === entryType)
      if (show) {
        if (index === -1) {
          this.filteredEntryTypes.push(entryType)
        }
      } else {
        if (index !== -1) {
          if (filterCopy.length === 1) {
            this.filteredEntryTypes = [...this.serviceHistoryEntryTypes]
          } else {
            this.filteredEntryTypes.splice(index, 1)
          }
        }
      }
      if (filterCopy.length !== this.filteredEntryTypes.length) {
        this.endCursorToLoad = null
        this.serviceHistory = []
      }
    },
    scrollToTop() {
      this.$refs.wrapper.scrollIntoView({ behavior: 'smooth', block: 'start' })
    },
    reset() {
      this.endCursor = null
      this.endCursorToLoad = null
      this.hasNextPage = false
      this.serviceHistory = []
      this.filteredEntryTypes = this.serviceHistoryEntryTypes
        ? [...this.serviceHistoryEntryTypes]
        : []

      this.editor?.commands.clearContent()
    },
    secondsToHours(seconds) {
      return Math.round((seconds / 3600) * 10) / 10
    },
  },
  watch: {
    'device.id'() {
      this.reset()
    },
    active() {
      if (this.active) {
        this.reset()
        this.$apollo.queries.serviceHistoryResult.refetch()
      }
    },
    '$i18n.locale'() {
      this.endCursorToLoad = null
      this.serviceHistory = []
    },
  },
}
</script>
<style lang="less" scoped>
@import '~@/assets/less/variables.less';
@import '~@/components/RichTextEditor/styles.less';

.entry {
  padding: 15px;
  background-color: fade(@color-brand-highlight, 50%);
  border-radius: 3px;
  margin-bottom: 5px;

  .metadata {
    display: block;
    font-size: 12px;
    color: @color-brand-2;
    opacity: 0.5;
    margin-bottom: 5px;
  }
}

.metadata-inside {
  font-size: 14px;
  color: @color-brand-2;
  opacity: 0.5;
}

.filter {
  span {
    font-weight: 500;
  }

  /deep/.v-input--checkbox {
    margin: 0;
    padding: 0;
  }
}

.event-code-notification,
.new-operating-cycle-text,
.rich-text {
  font-size: 14px;
}
</style>
