import gql from 'graphql-tag'

export const subscriptionKeyCreate = gql`
  mutation SubscriptionKeyCreate($input: SubscriptionKeyCreateInput!) {
    subscriptionKeyCreate(input: $input) {
      success
      subscriptionKey {
        id
        price
        currency
        subscriptionLength
        activeFrom
      }
    }
  }
`
