<template>
  <div>
    <v-dialog
      :value="isOpen"
      @input="$emit('modalToggle', $event)"
      width="unset"
      persistent
      @click:outside="cancel()"
      @keydown.esc="cancel()"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      content-class="modal-wrapper"
    >
      <v-btn
        v-if="!$vuetify.breakpoint.smAndDown"
        @click="cancel"
        color="white"
        class="close-btn"
        icon
        ><v-icon>mdi-close</v-icon></v-btn
      >
      <div class="modal-content-wrapper">
        <div class="schedule-form-view">
          <div class="pa-6 d-flex align-center justify-space-between">
            <h1>
              {{
                updateMode ? $t('global.editDailyTimeSchedule') : $t('global.newTimeSchedule.daily')
              }}
            </h1>
            <v-btn v-if="$vuetify.breakpoint.smAndDown" @click="cancel" icon
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </div>
          <v-text-field
            v-model="actualTimeScheduleName"
            :error-messages="visibleTimeScheduleNameErrors"
            :label="$t('global.timeScheduleName')"
            @input="$v.actualTimeScheduleName.$touch()"
            @blur="$v.actualTimeScheduleName.$touch()"
            filled
            counter="35"
            class="flex-grow-0 px-6"
          ></v-text-field>

          <div class="form-inputs mx-6">
            <div v-for="config of timeScheduleConfig.schedules" :key="config.name">
              <TimeScheduleFormInput
                ref="formInput"
                :timeSchedule="actualTimeSchedule"
                @change="actualTimeScheduleChange($event)"
                :timeScheduleConfig="timeScheduleConfig"
                :timeScheduleName="config.name"
                @calendarScrollTo="$refs.calendar ? $refs.calendar.scrollTo($event) : undefined"
              />
            </div>
          </div>

          <footer
            class="pa-6"
            :class="!updateMode || deleteMethod ? 'justify-space-between' : 'justify-end'"
          >
            <v-tooltip v-if="!updateMode || deleteMethod" top :disabled="!disabledDeleteMessage">
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-btn
                    :disabled="Boolean(disabledDeleteMessage)"
                    color="error"
                    text
                    @click="deleteClickHandler"
                    ><v-icon left>mdi-delete</v-icon>{{ $t('global.delete') }}</v-btn
                  >
                </div>
              </template>
              <span>{{ disabledDeleteMessage }}</span>
            </v-tooltip>
            <div class="d-flex">
              <v-btn
                v-if="updateMode"
                class="mr-3"
                :text="hasUnsavedChanges"
                :color="hasUnsavedChanges ? 'error' : undefined"
                depressed
                @click="cancel()"
                >{{ hasUnsavedChanges ? $t('global.discardChanges') : $t('global.cancel') }}</v-btn
              >
              <v-tooltip top :disabled="!disabledSaveMessage">
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <v-btn
                      :disabled="Boolean(disabledSaveMessage)"
                      color="primary"
                      depressed
                      @click="confirmSave()"
                      >{{ $t('global.save') }}</v-btn
                    >
                  </div>
                </template>
                <span>{{ disabledSaveMessage }}</span>
              </v-tooltip>
            </div>
          </footer>
        </div>
        <TimeScheduleCalendarInput
          v-if="!$vuetify.breakpoint.smAndDown"
          ref="calendar"
          :timeSchedule="actualTimeSchedule"
          @change="actualTimeScheduleChange($event)"
          :timeScheduleConfig="timeScheduleConfig"
          :editable="true"
        />
      </div>
    </v-dialog>
    <v-dialog v-model="selectSaveActionModalOpen" width="unset">
      <v-card>
        <v-card-title class="headline">{{ $t('global.saveWithWipModal.heading') }}</v-card-title>
        <v-card-text>{{ $t('global.saveWithWipModal.text') }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="selectSaveActionModalOpen = false" depressed>
            {{ $t('global.back') }}
          </v-btn>
          <v-btn color="primary" @click="handleSave(true)" depressed>
            {{ $t('global.saveWithWipModal.saveWithWip') }}
          </v-btn>
          <v-btn color="primary" @click="handleSave(false)" depressed>
            {{ $t('global.saveWithWipModal.saveWithoutWip') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="confirmSaveModalOpen" width="500">
      <v-card>
        <v-card-title class="headline">{{
          $t('global.saveDailyConfirmationModal.heading')
        }}</v-card-title>
        <v-card-text>
          <span
            v-sanitized-html="
              $t('global.saveDailyConfirmationModal.text', { scheduleName: timeSchedule.name })
            "
          ></span
          ><br /><br />
          <span
            v-for="weeklyScheduleName of usedInWeeklySchedulesNames"
            :key="weeklyScheduleName"
            class="d-block"
            ><b>{{ weeklyScheduleName }}</b></span
          >
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="confirmSaveModalOpen = false" depressed>
            {{ $t('global.back') }}
          </v-btn>
          <v-btn color="primary" @click="save()" depressed>
            {{ $t('global.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ConfirmationDialog
      v-model="confirmCancelEditDialogOpen"
      :heading="$t('device.timeSchedule.editModal.cancelConfirmation.heading')"
      :text="
        $t('device.timeSchedule.editModal.cancelConfirmation.text', {
          scheduleName: this.timeSchedule.name,
        })
      "
      :cancelText="$t('global.continueEditing')"
      :confirmText="$t('global.discardChanges')"
      confirmBtnColor="error"
      :action="cancelEdit"
    />
    <ConfirmationDialog
      v-model="deleteConfirmationModalOpen"
      :heading="$t('global.dailyTimScheduleDeleteConfirmationHeading')"
      :text="
        $t('global.dailyTimScheduleDeleteConfirmationText', { scheduleName: timeSchedule.name })
      "
      :confirmText="$t('global.delete')"
      confirmBtnColor="error"
      :action="deleteTimeSchedule"
    />
    <ConfirmationDialog
      v-model="deleteNewConfirmationModalOpen"
      :heading="$t('global.newDailyTimScheduleDeleteConfirmationHeading')"
      :text="$t('global.newDailyTimScheduleDeleteConfirmationText')"
      :confirmText="$t('global.delete')"
      confirmBtnColor="error"
      :action="cancelEdit"
    />
  </div>
</template>
<script>
import TimeScheduleFormInput from '@/components/TimeSchedules/TimeScheduleFormInput'
import TimeScheduleCalendarInput from '@/components/TimeSchedules/TimeScheduleCalendarInput'
import ConfirmationDialog from '@/components/ConfirmationDialog'
import { validationMixin } from 'vuelidate'
import { required, maxLength } from 'vuelidate/lib/validators'
import { normalizeAndSortTimeSchedule, mergeConsecutiveInTimeSchedule } from '@/utils/timeSchedule'
import produce from 'immer'
import { isUnique } from '@/utils/isUniqueValidator'
import unsavedChangesUnloadGuard from '@/mixins/unsavedChangesUnloadGuard'
import isEqual from 'lodash/isEqual'
import startOfDay from 'date-fns/startOfDay'
import differenceInSeconds from 'date-fns/differenceInSeconds'

export default {
  name: 'DailyTimeScheduleEditModal',
  mixins: [validationMixin, unsavedChangesUnloadGuard],
  components: { TimeScheduleFormInput, ConfirmationDialog, TimeScheduleCalendarInput },
  model: {
    prop: 'isOpen',
    event: 'modalToggle',
  },
  props: {
    isOpen: Boolean,
    timeSchedule: Object,
    timeScheduleConfig: Object,
    disabledDeleteMessage: String,
    saveMethod: Function,
    deleteMethod: Function,
    namesTaken: Array,
    updateMode: Boolean,
    usedInWeeklySchedulesNames: Array,
  },
  data() {
    return {
      actualTimeScheduleName: this.timeSchedule.name,
      actualTimeSchedule: normalizeAndSortTimeSchedule(this.timeSchedule.schedule),
      confirmCancelEditDialogOpen: false,
      selectSaveActionModalOpen: false,
      deleteConfirmationModalOpen: false,
      deleteNewConfirmationModalOpen: false,
      confirmSaveModalOpen: false,
    }
  },
  validations() {
    return {
      actualTimeScheduleName: {
        required,
        maxLength: maxLength(35),
        mustBeUnique: isUnique(this.namesTaken),
      },
    }
  },
  methods: {
    async confirmSave() {
      if (this.updateMode && this.usedInWeeklySchedulesNames?.length > 1) {
        this.confirmSaveModalOpen = true
      } else {
        this.save()
      }
    },
    async save() {
      this.confirmSaveModalOpen = false
      const hasWipInterval = Object.values(this.actualTimeSchedule)
        .flat()
        .some((interval) => interval.wip)
      if (hasWipInterval) {
        this.selectSaveActionModalOpen = true
      } else {
        await this.handleSave(false)
      }
    },
    async handleSave(withWip) {
      try {
        const newScheduleNotFiltered = produce(this.actualTimeSchedule, (draft) => {
          for (const name in draft) {
            if (!withWip) {
              draft[name] = draft[name].filter((interval) => !interval.wip)
            }
            draft[name] = draft[name].map((interval) => ({
              begin: interval.begin,
              end: interval.end,
              state: interval.state,
            }))
          }
        })
        // Filter out schedules ([{key: [...intervals]}]) that are not in the timeScheduleConfig (config.name)
        const newSchedule = Object.fromEntries(
          Object.entries(newScheduleNotFiltered).filter(([key]) =>
            this.timeScheduleConfig.schedules.some((schedule) => schedule.name === key),
          ),
        )
        await this.saveMethod(newSchedule, this.actualTimeScheduleName, this.timeSchedule.id)
        this.selectSaveActionModalOpen = false
        this.$emit('modalToggle', false)
      } catch (error) {
        this.$toast.error(error)
      }
    },
    async deleteTimeSchedule() {
      try {
        await this.deleteMethod(this.timeSchedule.id)
        this.deleteConfirmationModalOpen = false
        this.$emit('modalToggle', false)
      } catch (error) {
        this.$toast.error(error)
      }
    },
    actualTimeScheduleChange(newTimeSchedule) {
      this.actualTimeSchedule = mergeConsecutiveInTimeSchedule(newTimeSchedule)
    },
    cancel() {
      if (this.hasUnsavedChanges) {
        this.confirmCancelEditDialogOpen = true
      } else {
        this.cancelEdit()
      }
    },
    cancelEdit() {
      this.confirmCancelEditDialogOpen = false
      this.$emit('modalToggle', false)
    },
    deleteClickHandler() {
      if (this.updateMode) {
        this.deleteConfirmationModalOpen = true
      } else if (this.hasUnsavedChanges) {
        this.deleteNewConfirmationModalOpen = true
      } else {
        this.cancelEdit()
      }
    },
  },
  computed: {
    configMap() {
      const configMap = new Map()
      this.timeScheduleConfig.schedules.forEach((timeSchedule) => {
        configMap.set(timeSchedule.name, timeSchedule)
      })
      return configMap
    },
    actualTimeScheduleNameErrors() {
      const errors = []
      !this.$v.actualTimeScheduleName.required &&
        errors.push(this.$t('global.formValidation.required'))
      !this.$v.actualTimeScheduleName.maxLength &&
        errors.push(this.$t('global.formValidation.tooLong'))
      !this.$v.actualTimeScheduleName.mustBeUnique && errors.push(this.$t('global.nameTakenError'))
      return errors
    },
    visibleTimeScheduleNameErrors() {
      if (!this.$v.actualTimeScheduleName.$dirty) {
        return []
      } else {
        return this.actualTimeScheduleNameErrors
      }
    },
    disabledSaveMessage() {
      if (this.actualTimeScheduleNameErrors.length) {
        return this.$t('global.scheduleInvalidNameError')
      }
      if (this.updateMode && !this.hasUnsavedChanges) {
        return this.$t('global.noChangeError')
      }
      return null
    },
  },
  watch: {
    actualTimeSchedule() {
      if (
        !this.hasUnsavedChanges &&
        !isEqual(this.actualTimeSchedule, normalizeAndSortTimeSchedule(this.timeSchedule.schedule))
      ) {
        this.$store.commit('setHasUnsavedChanges', true)
      }
    },
    actualTimeScheduleName() {
      if (!this.hasUnsavedChanges && this.actualTimeScheduleName !== this.timeSchedule.name) {
        this.$store.commit('setHasUnsavedChanges', true)
      }
    },
    timeSchedule() {
      this.actualTimeSchedule = normalizeAndSortTimeSchedule(
        JSON.parse(JSON.stringify(this.timeSchedule.schedule)),
      )
    },
    isOpen: {
      immediate: true,
      handler() {
        this.$store.commit('setHasUnsavedChanges', false)
        if (this.isOpen) {
          this.$nextTick(() => {
            const now = new Date()
            this.$refs.calendar?.scrollTo(differenceInSeconds(now, startOfDay(now)))
          })
          this.actualTimeSchedule = normalizeAndSortTimeSchedule(
            JSON.parse(JSON.stringify(this.timeSchedule.schedule)),
          )
          this.actualTimeScheduleName = this.timeSchedule.name
        }
      },
    },
    nameEditOpen() {
      if (!this.nameEditOpen) {
        this.enterNewNameMode = false
      }
    },
  },
}
</script>
<style lang="less" scoped>
/deep/.modal-wrapper {
  height: 825px;
  overflow: visible;
  border-radius: 16px;
  position: relative;

  @media screen and (max-height: 850px) {
    height: 90%;
  }

  @media screen and (max-width: 768px) {
    height: 100%;
  }
}

.fullscreen-close-wrapper {
  background: white;
  border-bottom: 1px solid #e1e1e1;
  text-align: right;
}

.modal-content-wrapper {
  background-color: white;
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  height: 100%;
}

.schedule-form-view {
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  @media screen and (max-width: 768px) {
    flex-grow: 1;
  }

  h1 {
    font-size: 24px;
    line-height: 28px;
  }

  footer {
    display: flex;
    border-top: 1px solid #e8e8e8;
  }

  .form-inputs {
    overflow-y: auto;
    overflow-x: hidden;
    flex-grow: 1;
  }
}

.close-btn {
  position: absolute;
  top: -39px;
  right: -8px;
}
</style>
