export function lightenHexColor(hex, percent) {
  // Ensure the hex color starts with "#" and is in a valid format
  hex = hex.replace(/^#/, '')
  if (hex.length === 3) {
    hex = hex
      .split('')
      .map((c) => c + c)
      .join('')
  }

  // Convert hex to RGB
  let r = parseInt(hex.substring(0, 2), 16)
  let g = parseInt(hex.substring(2, 4), 16)
  let b = parseInt(hex.substring(4, 6), 16)

  // Increase brightness by the given percentage
  r = Math.min(255, Math.floor(r + (255 - r) * percent))
  g = Math.min(255, Math.floor(g + (255 - g) * percent))
  b = Math.min(255, Math.floor(b + (255 - b) * percent))

  // Convert back to hex and return
  return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b
    .toString(16)
    .padStart(2, '0')}`
}
